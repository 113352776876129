<template>
  <b-overlay :show="showOverlay">
    <b-col class="mb-1 bg-white p-0" cols="12">
      <DynamicBreadCrumb :items="pageBreadcrumb"></DynamicBreadCrumb>
    </b-col>
    <validation-observer ref="createProductFeature" tag="div">
      <b-card class="data-edit-wrapper">
        <h2>Field Features</h2>
        <b-form class="mt-2">
          <b-row>
            <b-col class="" md="4">
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-group
                    label="Name"
                >

                  <b-form-input
                      id="data-edit-seoTitle"
                      ref="fieldName"
                      v-model="data.name"
                      :state="errors.length > 0 ? false:null"

                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col class="" md="4">
              <b-form-group
                  label="Hint"
              >
                <b-form-input
                    id="data-edit-seoTitle"
                    ref="fieldName"
                    v-model="data.hint"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-row>
                <b-col v-for="(item,idx) in currencies" md="3">
                  <b-form-group
                      :label="`Price In ${item.name}`"
                  >
                    <b-form-input
                        id="data-edit-seoTitle"
                        :ref="`price${idx}`"
                        type="number"
                    />

                  </b-form-group>
                </b-col>
              </b-row>

            </b-col>
            <b-col v-if="fieldDetail &&fieldDetail.productFieldDetailTypeId === 4" cols="12">
              <b-row>
                <b-col cols="3">
                  <b-form-group
                      label="Slider Min Value"
                  >
                    <b-form-input
                        id="data-edit-seoTitle"
                        ref="fieldName"
                        v-model="data.sliderMinValue"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group
                      label="Slider Max Value"
                  >
                    <b-form-input
                        id="data-edit-seoTitle"
                        ref="fieldName"
                        v-model="data.sliderMaxValue"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group
                      label="Slider Default Value"
                  >
                    <b-form-input
                        id="data-edit-seoTitle"
                        ref="fieldName"
                        v-model="data.sliderDefaultValue"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

            </b-col>
            <b-col cols="12">
              <b-button
                  class=" "
                  variant="primary"
                  @click="createProductFeature"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </validation-observer>

  </b-overlay>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BOverlay, BRow, } from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ckEditorMain from '@/views/components/ckEditorMain'
import Helper from '@/libs/Helper'
import MediaHandler from '@/views/components/utilities/MediaHandler.vue'
import { CreateProductFieldFeature, GetProductFieldDetail } from '@/libs/Api/productField'
import { GetAllCurrencies } from '@/libs/Api/Currencies'
import DynamicBreadCrumb from '@/views/components/utilities/DynamicBreadCrumb.vue'

export default {
  components: {
    DynamicBreadCrumb,
    MediaHandler,
    BForm,
    ValidationProvider,
    ckEditorMain,
    BCard,
    ValidationObserver,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BOverlay,
    vSelect,
  },
  name: 'create-product',
  title: 'create product',
  data() {
    return {

      required,
      showOverlay: false,
      baseUrl: Helper.baseUrl,
      fieldDetail: null,
      currencies: null,
      pageBreadcrumb: [
        {
          text: 'Manage Games',
          href: '/apps/games/manage-games',
          active: false
        },
        {
          text: `Manage Game`,
          href: `/apps/games/manage-game?gameId=${this.$route.query.gameId}`,
          active: false
        },
        {
          text: `Manage Game Service Category`,
          href: `/apps/games/game-service-categories/manage-game-service-category?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}`,
          active: false
        },
        {
          text: `Manage Game Service`,
          href: `/apps/games/game-service-categories/game-service/manage-game-services?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}`,
          active: false
        },
        {
          text: `Manage Product`,
          href: `/apps/games/game-service-categories/game-service/products/manage-product?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}&productId=${this.$route.query.productId}`,
          active: false
        },
        {
          text: `Manage Product Field`,
          href: `/apps/games/game-service-categories/game-service/products/product-field/manage-product-field?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}&productId=${this.$route.query.productId}&productFieldDetailId=${this.$route.query.productFieldDetailId}`,
          active: false
        },
        {
          text: `Create Product Feature`,
          href: `/apps/games/game-service-categories/game-service/products/product-field/product-feature/create-product-feature?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}&productId=${this.$route.query.productId}&productFieldDetailId=${this.$route.query.productFieldDetailId}`,
          active: true
        },
      ],

      data: {
        productFieldDetailId: this.$route.query.productFieldDetailId,
        hint: '',
        name: '',
        createProductFeaturePrices: [],
        sliderMaxValue: null,
        sliderMinValue: null,
        sliderDefaultValue: null
      }
    }
  },

  async created() {
    await Promise.all([
      this.getFieldDetail(),
      this.getAllCurrencies()
    ])
  },
  methods: {
    async getAllCurrencies() {
      let _this = this
      _this.showOverlay = true
      let getAllCurrencies = new GetAllCurrencies(_this)
      let data = {
        pageNumber: 1,
        count: 20,
      }
      getAllCurrencies.setParams(data)
      await getAllCurrencies.fetch(function (content) {
        _this.currencies = content.data.getCurrencies
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async getFieldDetail() {
      let _this = this
      _this.showOverlay = true
      let getProductFieldDetail = new GetProductFieldDetail(_this)
      let data = {
        productFieldDetailId: _this.$route.query.productFieldDetailId
      }
      getProductFieldDetail.setParams(data)
      await getProductFieldDetail.fetch(function (content) {
        _this.showOverlay = false
        _this.fieldDetail = content.data
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    createPayload() {
      let isValid = true
      for (let i = 0; i < this.currencies.length; i++) {
        if (!this.$refs[`price${i}`][0]?.$el.value) {
          isValid = false
        }
      }
      this.currencies.forEach((item, idx) => {
        if (isValid) {
          this.data.createProductFeaturePrices.push({
            currencyId: item.id,
            priceValue: this.$refs[`price${idx}`][0]?.$el.value
          })
        }
      })
      return this.data
    },
    async createProductFeature() {
      let _this = this
      Helper.validateForm(_this, 'createProductFeature', async () => {
        let data = _this.createPayload()
        _this.showOverlay = true
        let createProductFieldFeature = new CreateProductFieldFeature(_this)
        createProductFieldFeature.setParams(data)
        await createProductFieldFeature.fetch(function (content) {
          _this.showOverlay = false
          if (content.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            _this.$router.push(`/apps/games/game-service-categories/game-service/products/product-field/manage-product-field?gameId=${_this.$route.query.gameId}&gameServiceCategoryId=${_this.$route.query.gameServiceCategoryId}&gameServiceId=${_this.$route.query.gameServiceId}&productId=${_this.$route.query.productId}&productFieldDetailId=${_this.$route.query.productFieldDetailId}`)
          } else {
            return _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: content.message,
                title: `error`,
                variant: 'danger',
              },
            })
          }
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      })
    },

  }
}
</script>

<style scoped>

</style>
